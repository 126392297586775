import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { showNotification } from "../features/common/headerSlice";

// Function to initialize application
const InitApp = () => {
    // Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyClp7E9PtmI7nUr28U5F3lhwSBenzgXlUo",
        authDomain: "sigarda-notes.firebaseapp.com",
        projectId: "sigarda-notes",
        storageBucket: "sigarda-notes.appspot.com",
        messagingSenderId: "191406497530",
        appId: "1:191406497530:web:21f201d92f3420bb477343",
        measurementId: "G-V9SB9XHMH1"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Initialize Analytics (if supported)
    let analytics;
    if (window.innerWidth > 768) { // Example check for non-mobile
        try {
            analytics = getAnalytics(app);
        } catch (error) {
            console.error('Firebase Analytics initialization failed:', error);
        }
    }

    // Initialize Firebase Messaging
    const messaging = getMessaging(app);

    // Set base URL for all axios requests
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

    // Check Notification Permission
    if (window.Notification && Notification.permission === 'default') {
        // Request permission to show notifications
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            } else {
                console.log('Please enable notifications to receive updates.');
            }
        }).catch(err => {
            console.error('Failed to request notification permission:', err);
            console.log('An error occurred while requesting notification permission.');
        });
    } else if (window.Notification && Notification.permission === 'denied') {
        console.log('Notifications are blocked. Please enable them in your browser settings.');
    }

    // Service Worker registration
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;

            navigator.serviceWorker.register(swUrl)
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        });
    } else {
        console.warn('Service Worker is not supported in this browser.');
    }

    return { messaging };
};

// Request FCM token
export const requestForToken = async (messaging, dispatch) => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BEDeEcLb7rpXMPNtE8Oxvm-s3HGzPmJ_yKxfr19incHJl8hpc3S-G7A5YcqEC0VdPZ73YeQBjoMk9FlXM5iGh2w' });
        if (currentToken) {
            console.log('FCM Token:', currentToken);
            // Send the token to your server to subscribe the user to notifications
        } else {
            console.log('No registration token available. Request permission to generate one.');
            if (dispatch) {
                dispatch(showNotification({ message: "No registration token available. Request permission to generate one.", status: 0 }));
            }
        }
    } catch (err) {
        console.error('An error occurred while retrieving token. ', err);
        if (dispatch) {
            dispatch(showNotification({ message: "An error occurred while retrieving token. ", status: 0 }));
        }
    }
};

// Listen for incoming messages
export const onMessageListener = (messaging) =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export default InitApp;
